<template>
  <div
    v-if="text !== undefined && url !== undefined && ctaText !== undefined && !isDismissed"
    :class="['flex justify-center items-center p-3', bgColor]"
  >
    <div
      class="flex flex-1 justify-center items-center text-xs text-center text-black font-semibold"
    >
      <p>
        {{ text }}
        <span class="whitespace-nowrap">
          <span class="font-bold mr-1">—</span>
          <a :href="url" :target="target" class="font-bold underline">{{ ctaText }}</a>
        </span>
      </p>
    </div>
    <icon-button
      class="mx-auto px-4"
      :color="'black-transparent'"
      :size="'xl'"
      :icon="iconXmark"
      :on-click="dismissBanner"
      :tooltip="'Hide Banner'"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import {usePromotions} from '../../../vue-composition/promotion/promotion';
import {Promotion, PromotionCopyHeading} from '../../../../backend/promotion/promotion-types';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import IconButton from '../../../core/button/IconButton.vue';
import {usePromoColors} from '../../../vue-composition/promotion/promo-colors';

export default defineComponent({
  components: {
    IconButton
  },
  props: {
    guest: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true},
    segment: {type: String, default: undefined}
  },
  setup(props) {
    const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);
    const promo = ref<Readonly<Promotion> | undefined>(undefined);
    const iconXmark = computed(() => faXmark);
    const isDismissed = ref(false);
    const localStorageKey = ref<string | undefined>(undefined);

    onMounted(async () => {
      await promotions.init();
      promo.value = promotions.get('headerBanner');

      // Check if the banner has been previously dismissed
      if (promo.value?.id && promo.value?.postDate) {
        localStorageKey.value = `promo-dismissed-${promo.value.id}-${promo.value.postDate}`;
        const storedValue = localStorage.getItem(localStorageKey.value);
        isDismissed.value = storedValue === 'true';
      }
    });

    const dismissBanner = () => {
      if (localStorageKey.value) {
        localStorage.setItem(localStorageKey.value, 'true');
        isDismissed.value = true;
      }
    };

    const showBanner = computed(() => {
      if (!promo.value) return false;
      /*
        The banner is only shown on allowed pages.  
        If logged in, it is displayed on every page.
        For guests, it is shown on all pages **except** those explicitly excluded  
        based on `restrictedBaseRoutes` and `restrictedSubroutes`, f.e. login
        page and routes containing media browser.
      */
      const pathSegments = window.location.pathname.split('/').filter(Boolean);
      const fullPath = pathSegments.join('/');

      if (props.guest) {
        const restrictedBaseRoutes = ['lessons', 'players-path-pieces', 'login'];
        const restrictedSubRoutes = [
          'learning-pathways',
          'learning-pathways-courses',
          'players-path',
          'courses',
          'interviews',
          'shows/student-focus'
        ];

        return !(
          (props.segment && restrictedBaseRoutes.includes(props.segment)) ||
          restrictedSubRoutes.some(
            route => fullPath.startsWith(route) && pathSegments.length > route.split('/').length
          )
        );
      }

      return true; // If logged in, show the banner everywhere
    });

    const cta = computed(() => {
      if (promo.value === undefined || showBanner.value === false) {
        return undefined;
      }
      return promo.value.promotionCallToAction[0];
    });

    const url = computed(() => {
      if (cta.value !== undefined && showBanner.value) {
        return cta.value.promotionCtaUrl;
      }
      return undefined;
    });
    const ctaText = computed(() => {
      if (cta.value !== undefined && showBanner.value) {
        return cta.value.promotionCtaText;
      }
      return undefined;
    });

    const text = computed(() => {
      if (promo.value !== undefined && showBanner.value) {
        const firstHeading = promo.value.promotionCopy.find(
          block => block.typeHandle === 'promotionCopyHeading'
        );
        if (firstHeading === undefined) {
          return undefined;
        }
        return (firstHeading as Readonly<PromotionCopyHeading>).promotionCopyHeadingText;
      }
      return undefined;
    });

    const {bgColor} = usePromoColors(promo);

    const target = computed(() => {
      if (cta.value === undefined) {
        return undefined;
      }
      if (cta.value.promotionCtaOpenInNewTab) {
        return '_blank';
      }
      return undefined;
    });

    return {
      url,
      text,
      bgColor,
      ctaText,
      target,
      iconXmark,
      dismissBanner,
      isDismissed
    };
  }
});
</script>
