import {fetchEntries} from '../craft/entry/entry-query';
import {fetchGlobalSet} from '../craft/global-set/global-set-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {Promotion, PromotionGroup, PromotionGroups} from './promotion-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';
import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {ControllerResponse} from '../craft-action';

const PROMOTION_COPY_FIELD = QueryBuilder.matrix('promotionCopy').blocks([
  QueryBuilder.block('promotionCopyHeading').fields([
    QueryBuilder.dropdown('promotionCopyHeadingSize', ['large', 'small']).required(),
    QueryBuilder.plainText('promotionCopyHeadingText').required()
  ]),
  QueryBuilder.block('promotionCopyBody').fields([
    QueryBuilder.plainText('promotionCopyBodyParagraphs') // ??? Redactor plugin is no longer supported
  ])
]);

const PROMOTION_CALL_TO_ACTION_FIELD = QueryBuilder.matrix('promotionCallToAction').blocks([
  QueryBuilder.block('promotionCta').fields([
    QueryBuilder.plainText('promotionCtaText').required(),
    QueryBuilder.url('promotionCtaUrl').required(),
    QueryBuilder.lightswitch('promotionCtaOpenInNewTab').required()
  ])
]);

const PROMOTION_CONDITIONS_SERVER_DATE_BLOCK = QueryBuilder.block('serverDateInterval').fields([
  QueryBuilder.date('startDate'),
  QueryBuilder.date('endDate')
]);
const PROMOTION_CONDITIONS_SIGNUP_DATE_BLOCK = QueryBuilder.block('signupDateInterval').fields([
  QueryBuilder.date('startDate'),
  QueryBuilder.date('endDate')
]);
const PROMOTION_CONDITIONS_VISITOR_STATUS_BLOCK = QueryBuilder.block('visitorStatus').fields([
  QueryBuilder.dropdown('visitorStatus', ['guest', 'loggedIn']).required()
]);
const PROMOTION_CONDITIONS_ACCESS_PASSES_BLOCK = QueryBuilder.block('accessPasses').fields([
  QueryBuilder.categories('accessPasses').required(),
  QueryBuilder.dropdown('accessPassStatus', ['active', 'trial', 'pastDue', 'inactive']).required()
]);
const PROMOTION_CONDITIONS_FIELD = QueryBuilder.matrix('promotionConditions').blocks([
  PROMOTION_CONDITIONS_SERVER_DATE_BLOCK,
  PROMOTION_CONDITIONS_SIGNUP_DATE_BLOCK,
  PROMOTION_CONDITIONS_VISITOR_STATUS_BLOCK,
  PROMOTION_CONDITIONS_ACCESS_PASSES_BLOCK
]);

const PROMOTION_FIELDS = [
  IMAGE_COLLECTION_FIELD,
  PROMOTION_CONDITIONS_FIELD,
  PROMOTION_COPY_FIELD,
  PROMOTION_CALL_TO_ACTION_FIELD,
  QueryBuilder.multiselect('promotionLocations', [
    'dashboard',
    'coursesIndex',
    'survey',
    'headerBanner',
    'notifications'
  ]).required(),
  QueryBuilder.dropdown('promotionColor', [
    'orangeFury',
    'techniqueAccelerator',
    'practiceAccelerator',
    'slapAccelerator',
    'fretboardAccelerator',
    'jazzAccelerator',
    'goldGradient'
  ])
];

const PROMOTION_GROUP = QueryBuilder.matrix('promotionGroups').blocks([
  QueryBuilder.block('promotionGroup').fields([
    QueryBuilder.entries('promotions'),
    QueryBuilder.lightswitch('active').required()
  ])
]);

const promotionsCache = new ThreadSyncCache<ControllerResponse<ReadonlyArray<Promotion>>>();
export async function getAllPromotions(): Promise<ReadonlyArray<Promotion>> {
  const result = await fetchEntries<Promotion>(
    QueryBuilder.entries().section(['promotions']).fields(PROMOTION_FIELDS),
    undefined,
    undefined,
    promotionsCache,
    'promotions'
  );
  return result;
}

const promotionsGroupCache = new ThreadSyncCache<
  ControllerResponse<ReadonlyArray<PromotionGroups>>
>();
export async function getPromotionGroups(): Promise<Array<PromotionGroup>> {
  const result = await fetchGlobalSet<PromotionGroups>(
    QueryBuilder.globalSet('promotionGroups').fields([PROMOTION_GROUP]),
    promotionsGroupCache,
    'promoGrps'
  );
  return result.promotionGroups;
}
