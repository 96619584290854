<template>
  <sidebar-menu
    :guest="guest"
    :menu="filteredMenu"
    :on-close="onClose"
    :title="title"
    :orientation="orientation"
  >
    <a
      v-if="guest"
      id="trinity-trial-button"
      class="py-fl-xs px-fl-sm text-fl-base font-semibold text-center whitespace-nowrap text-black hover:text-primary bg-primary hover:bg-transparent border-2 border-primary rounded-md flex flex-nowrap justify-center"
      href="/sign-up"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="ctaTitle"></div>
    </a>
  </sidebar-menu>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType} from 'vue';
import SidebarMenu from '../../../generic/sidebar-menu/SidebarMenu.vue';
import {HierarchicalMenuItem} from '../../../generic/hierarchical-menu/hierarchical-menu-types';
import {useUserAccess} from '../../../vue-composition/user-access/user-access';

export default defineComponent({
  components: {
    SidebarMenu
  },
  props: {
    guest: {type: String, required: true},
    menu: {type: Object as PropType<Readonly<HierarchicalMenuItem>>, required: true},
    onClose: {type: Function as PropType<() => void>, required: true},
    title: {type: String, default: undefined},
    ctaTitle: {type: String, required: true},
    orientation: {type: String as PropType<'left' | 'right'>, default: 'left'}
  },
  setup(props) {
    const userAccess = useUserAccess();
    onMounted(userAccess.init);

    // Filter out menu items that require a specific access pass
    const filteredMenu = computed(() => {
      const restrictedItems: Record<string, string> = {
        LiveClass: 'ap-live-class'
      };

      return {
        ...props.menu,
        children: props.menu.children.filter(item => {
          if (!item.title) return true;

          const requiredPass = restrictedItems[item.title as keyof typeof restrictedItems];
          return !requiredPass || userAccess.userHasAccessPassSlug(requiredPass);
        })
      };
    });

    return {filteredMenu};
  }
});
</script>
