// useMessageNotifications.ts
import {computed} from 'vue';
import {
  MessageArray,
  MessageBody,
  MessageHeading,
  MessageNotificationBlock
} from '../../../backend/message/message-types';
import {isMessageRead} from './message';
import {NotificationsArray} from '../notifications/types';

export function useMessageNotifications(activeMessages: {[key: string]: MessageArray}) {
  return computed(() => {
    // Array of notifications to be returned
    const messages: NotificationsArray = [];

    // Loop through each location in activeMessages
    for (const location in activeMessages) {
      const locationMessages = activeMessages[location] as MessageArray;

      // Loop through each message in the array for this location
      for (const locationMessage of locationMessages) {
        const read = isMessageRead(locationMessage);

        if (locationMessage && Array.isArray(locationMessage.message)) {
          const headingBlock = locationMessage.message.find(
            block => block.typeHandle === 'messageHeading'
          ) as MessageHeading | undefined;

          const bodyBlock = locationMessage.message.find(
            block => block.typeHandle === 'messageBody'
          ) as MessageBody | undefined;

          const notificationBlock = locationMessage.messageNotification[0] as
            | MessageNotificationBlock
            | undefined;

          // If notification block is undefined or false in craft, skip this message
          if (!notificationBlock?.messageIsNotification) {
            continue;
          }

          // Check if messages have multiple locations to avoid duplicates
          if (!messages.find(msg => msg.id === locationMessage.id)) {
            messages.push({
              id: locationMessage.id,
              read: read,
              heading: headingBlock?.messageHeadingText || '',
              body: bodyBlock?.messageBodyParagraphs || '',
              postDate: locationMessage.postDate,
              notificationUrl: notificationBlock?.messageNotificationUrl || '',
              notificationUrlNewTab: notificationBlock?.messageNotificationNewTab || false,
              notificationAvatar: notificationBlock?.messageNotificationAvatar || ''
            });
          }
        }
      }
    }
    return messages;
  });
}
