<template>
  <div
    v-if="promo !== undefined"
    :class="[
      'w-full flex items-center justify-between pt-4 px-4 bg-gradient-to-r shadow-md relative',
      bgColor
    ]"
  >
    <div class="flex items-center space-x-3 pr-14">
      <div>
        <!-- Promotion Heading -->
        <div class="mb-3 flex items-center space-x-2 whitespace-nowrap">
          <font-awesome-icon class="text-dark" :icon="icon" :size="'lg'" />
          <p class="text-sm font-semibold italic text-dark">
            {{ headingBlock?.promotionCopyHeadingText }}
          </p>
        </div>

        <!-- Promotion Body -->
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="text-almost-black [&_*]:text-[15px] font-semibold mr-1"
          v-html="bodyBlock?.promotionCopyBodyParagraphs"
        ></span>
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>

    <!-- Promotion Arrow CTA Button -->
    <icon-button
      class="absolute bottom-6 right-6"
      :icon="arrowIcon"
      :tooltip="'Go to promotion!'"
      color="black-transparent"
      size="xl"
      :url="cta?.url"
      :target="cta?.newTab ? '_blank' : '_self'"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted} from 'vue';
import IconButton from '../../../core/button/IconButton.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faMegaphone} from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons/faArrowRight';
import {usePromotions} from '../../../vue-composition/promotion/promotion';
import {usePromoColors} from '../../../vue-composition/promotion/promo-colors';
import {
  PromotionLocation,
  PromotionCopyHeading,
  PromotionCopyBody
} from '../../../../backend/promotion/promotion-types';

const location: PromotionLocation = 'notifications';
const icon = faMegaphone;
const arrowIcon = faArrowRight;

const props = defineProps({
  guest: {type: String, required: true},
  serverDate: {type: String, required: true},
  userDateCreated: {type: String, required: true}
});

const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);

onMounted(async () => {
  if (!props.guest) {
    await promotions.init();
  }
});

const promo = computed(() => {
  return promotions.get(location);
});

const headingBlock = computed(() => {
  return promo.value?.promotionCopy.find(block => block.typeHandle === 'promotionCopyHeading') as
    | PromotionCopyHeading
    | undefined;
});

const bodyBlock = computed(() => {
  return promo.value?.promotionCopy.find(block => block.typeHandle === 'promotionCopyBody') as
    | PromotionCopyBody
    | undefined;
});

const cta = computed(() => {
  if (promo.value === undefined) {
    return undefined;
  }
  if (promo.value.promotionCallToAction.length === 0) {
    return undefined;
  }
  const cta = promo.value.promotionCallToAction[0];
  const text = cta.promotionCtaText;
  const url = cta.promotionCtaUrl;
  const newTab = cta.promotionCtaOpenInNewTab;

  return {text, url, newTab};
});

const {bgColor} = usePromoColors(promo);
</script>
