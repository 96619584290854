<template>
  <nav aria-label="Footer links">
    <cluster :gap="'gap-4'" :justify="'justify-center'">
      <link-button :size="'md'" url="/contact" :label="'Contact'" :color="'light'" />
      <link-button :size="'md'" url="/privacy-policy" :label="'Privacy'" :color="'light'" />
      <link-button :size="'md'" url="/terms-of-service" :label="'Legal'" :color="'light'" />
      <link-button :size="'md'" url="/cookie-policy" :label="'Cookie Policy'" :color="'light'" />
    </cluster>
  </nav>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Cluster from '../../core/compositions/Cluster.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook';
import {faTiktok} from '@fortawesome/free-brands-svg-icons/faTiktok';

export default defineComponent({
  components: {
    Cluster,
    LinkButton
  },
  setup() {
    return {
      iconYoutube: computed(() => faYoutube),
      iconInstagram: computed(() => faInstagram),
      iconFacebook: computed(() => faFacebook),
      iconTiktok: computed(() => faTiktok)
    };
  }
});
</script>
