<template>
  <button
    v-if="onClick !== undefined"
    :disabled="disabled"
    :class="`${cfg} select-none`"
    :title="tooltip"
    :aria-label="tooltip"
    @click.prevent="clicked"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      aria-hidden="true"
      :class="iconClass"
    ></font-awesome-icon>
    <!-- Badge display for buttons -->
    <span
      v-if="badge"
      class="badge absolute top-0 left-0 text-xs text-black bg-primary rounded-full flex items-center justify-center"
      :style="{...badgeOffset, position: 'absolute'}"
    >
      {{ badge }}
    </span>
  </button>
  <a v-else :href="url" :class="`${cfg} select-none`">
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      aria-hidden="true"
      :class="iconClass"
    ></font-awesome-icon>

    <!-- Badge display for anchor links -->
    <span
      v-if="badge"
      class="badge absolute top-0 left-0 text-xs text-black bg-primary rounded-full flex items-center justify-center"
      :style="{...badgeOffset, position: 'absolute'}"
    >
      {{ badge }}
    </span>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {
  ButtonClickCallback,
  getClickFunc,
  getIconDimensions,
  getLinkColor,
  IconButtonSize
} from './implementation/utils';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ButtonColor} from './implementation/utils';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {type: Boolean, default: false},

    /** If true, set the width of the button to 100%. */
    expand: {type: Boolean, default: false},

    size: {type: String as PropType<IconButtonSize>, default: 'md'},
    fontSize: {type: String, default: undefined},

    color: {type: String as PropType<ButtonColor>, default: 'mid'},

    tooltip: {type: String, required: true},

    /** FontAwesome icon (positioned left of the label). */
    icon: {type: Object as PropType<Readonly<IconDefinition>>, required: true},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: Number, default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined},

    /** Optional badge for notification count. */
    badge: {type: [String, Number], default: null},
    badgeOffset: {
      type: Object as PropType<{top?: string; left?: string}>,
      default: () => ({top: '-7px', left: '16px'})
    }
  },
  setup(props) {
    const clicked = getClickFunc(props.onClick, props.id, props.url);
    const color = computed(() => getLinkColor(props.disabled, props.color));
    const dim = computed(() => props.fontSize || getIconDimensions(props.size, props.expand));
    const pointer = computed(() => {
      return props.disabled ? '' : 'cursor-pointer';
    });

    const cfg = computed(() => {
      // 'flex' needed here to avoid Safari bug where grid/flex won't position icon SVG element correctly
      return `flex ${dim.value} ${color.value.textColor} ${color.value.bgColor} ${pointer.value}`;
    });

    const iconClass = computed(() => {
      return props.expand ? 'w-full h-full' : '';
    });

    return {
      cfg,
      clicked,
      iconClass
    };
  }
});
</script>

<style scoped>
.badge {
  position: absolute;
  background-color: primary;
  color: black;
  width: auto;
  height: 16px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 0.7rem;
  font-weight: 600;
}
</style>
