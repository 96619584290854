import {ref, Ref} from 'vue';

/**
 * Composable: useTouchEvents
 *
 * Provides reusable touch event handlers for swipe gestures in Vue components.
 *
 * @param {Function} handleSwipe - Callback triggered when the swipe distance exceeds the threshold.
 * @param {number} threshold - Minimum swipe distance to trigger the `handleSwipe` callback (default: 80px).
 * @param {number} maxThreshold - Maximum swipe distance to cap the `translateX` value (default: 100% of viewport width).
 * @param {Ref<boolean>} preventSwipe - Reactive ref to disable swipe gesture.
 *
 * @returns {Object}
 * - `translateX` (Ref): Reactive value tracking the swipe distance.
 * - `handleTouchStart` (Function): Starts tracking the touch gesture.
 * - `handleTouchMove` (Function): Calculates swipe distance.
 * - `handleTouchEnd` (Function): Finalizes the swipe and triggers `handleSwipe` if valid.
 */

export function useTouchEvents(
  handleSwipe: (translateX: number) => void,
  threshold: number = 80,
  maxThreshold: number = window.innerWidth, // Default: 100% of viewport width
  preventSwipe: Ref<boolean> = ref(false)
) {
  const translateX = ref(0);
  const startX = ref(0);
  const isSwiping = ref(false);

  const handleTouchStart = (event: TouchEvent) => {
    if (!preventSwipe.value) {
      isSwiping.value = true;
      startX.value = event.touches[0].clientX; // Record initial position
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (isSwiping.value && !preventSwipe.value) {
      // Calculate the swipe distance
      const distance = event.touches[0].clientX - startX.value;

      // Clamp `translateX` within the max threshold
      translateX.value = Math.max(Math.min(distance, maxThreshold), -maxThreshold);
    } else {
      translateX.value = 0; // Reset position if swipe is disabled
    }
  };

  const handleTouchEnd = () => {
    if (isSwiping.value) {
      if (Math.abs(translateX.value) > threshold) {
        handleSwipe(translateX.value); // Trigger swipe action
      } else {
        translateX.value = 0; // Reset position if below the threshold
      }
    }
    isSwiping.value = false;
  };

  return {
    translateX,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  };
}
