<template>
  <notification-dropdown
    v-if="dropdownOpen"
    ref="dropdown"
    :notifications="notifications"
    :guest="guest"
    :server-date="serverDate"
    :user-date-created="userDateCreated"
    :is-narrow="isNarrow"
    @updateDropdownOpen="handleDropdownOpen"
  />
  <icon-button
    :class="['relative', {'text-white': dropdownOpen}]"
    :icon="icon"
    :tooltip="'Notifications'"
    :size="size"
    :badge="unreadNotifications"
    :badge-offset="badgeOffset"
    @click="toggleDropdown"
  />
</template>

<script setup lang="ts">
import {computed, ref, PropType, onUnmounted, watch} from 'vue';
import IconButton from '../core/button/IconButton.vue';
import {faBell} from '@fortawesome/pro-solid-svg-icons/faBell';
import NotificationDropdown from './NotificationDropdown.vue';
import {useNotifications} from '../vue-composition/notifications/notifications';
import {IconButtonSize} from '../core/button/implementation/utils';

const props = defineProps({
  guest: {type: String, required: true},
  serverDate: {type: String, required: true},
  userDateCreated: {type: String, required: true},
  size: {type: String as PropType<IconButtonSize>, default: 'lg'},
  isNarrow: {type: Boolean, default: false}
});

const icon = computed(() => faBell);
const dropdownOpen = ref(false);

const {notifications, unreadNotifications} = useNotifications(
  props.guest,
  props.userDateCreated,
  props.serverDate
);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

// Handle dropdown child close event
const handleDropdownOpen = (newDropdownOpen: boolean) => {
  dropdownOpen.value = newDropdownOpen;
};

/**
 * Handle dropdown interactions:
 * - Close dropdown on outside clicks and prevent actions on underlying elements.
 * - Disable body scrolling on mobile (narrow) screens while dropdown is open.
 * - Add/remove event listeners dynamically on open/close.
 */

const dropdown = ref<InstanceType<typeof NotificationDropdown> | HTMLElement | null>(null);

const closeDropdownOnOutsideClick = (event: MouseEvent) => {
  const dropdownEl = dropdown.value instanceof HTMLElement ? dropdown.value : dropdown.value?.$el;

  if (dropdownEl instanceof HTMLElement && !dropdownEl.contains(event.target as Node)) {
    event.preventDefault(); // Prevent interaction with underlying elements
    event.stopPropagation(); // Prevent bubbling
    dropdownOpen.value = false;
  }
};

const preventScrollOutsideDropdown = (event: Event) => {
  const dropdownEl = dropdown.value instanceof HTMLElement ? dropdown.value : dropdown.value?.$el;

  if (dropdownEl instanceof HTMLElement && !dropdownEl.contains(event.target as Node)) {
    event.preventDefault();
  }
};

watch(dropdownOpen, isOpen => {
  if (isOpen) {
    document.addEventListener('click', closeDropdownOnOutsideClick, {capture: true});
    if (props.isNarrow) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('wheel', preventScrollOutsideDropdown, {passive: false});
      document.addEventListener('touchmove', preventScrollOutsideDropdown, {passive: false});
    }
  } else {
    document.removeEventListener('click', closeDropdownOnOutsideClick, {capture: true});
    document.body.style.overflow = '';
    document.removeEventListener('wheel', preventScrollOutsideDropdown);
    document.removeEventListener('touchmove', preventScrollOutsideDropdown);
  }
});

onUnmounted(() => {
  document.removeEventListener('click', closeDropdownOnOutsideClick, {capture: true});
  document.body.style.overflow = '';
  document.removeEventListener('wheel', preventScrollOutsideDropdown);
  document.removeEventListener('touchmove', preventScrollOutsideDropdown);
});

// Notification bell button badge counter visual offset
const badgeOffset = computed(() => {
  switch (props.size) {
    case 'lg':
      return {top: '-7px', left: '16px'};
    case 'xl':
      return {top: '-6px', left: '20px'};
    default:
      return {top: '-7px', left: '16px'};
  }
});
</script>
