// src/utils/promo-colors.ts
import { computed } from 'vue';

/**
 * Promotion Colors:
 * If adding more colors in Craft, map them here as well as 
 * add them in the backend promotion types and query files.
 */

const bgColorMap: Record<string, string> = {
  orangeFury: 'bg-orange-fury',
  techniqueAccelerator: 'bg-technique-accelerator',
  practiceAccelerator: 'bg-practice-accelerator',
  slapAccelerator: 'bg-slap-accelerator',
  fretboardAccelerator: 'bg-fretboard-accelerator',
  jazzAccelerator: 'bg-jazz-accelerator',
  goldGradient: 'bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end'
};

const borderColorMap: Record<string, string> = {
  orangeFury: 'border-orange-fury',
  techniqueAccelerator: 'border-technique-accelerator',
  practiceAccelerator: 'border-practice-accelerator',
  slapAccelerator: 'border-slap-accelerator',
  fretboardAccelerator: 'border-fretboard-accelerator',
  jazzAccelerator: 'border-jazz-accelerator',
  goldGradient: 'border-gold-gradient-start' // Tailwind does not support gradient borders
};

export function usePromoColors(promo: { value?: { promotionColor?: string } }) {
  return {
    bgColor: computed(() => bgColorMap[promo.value?.promotionColor ?? 'orangeFury'] ?? 'bg-orange-fury'),
    borderColor: computed(() => borderColorMap[promo.value?.promotionColor ?? 'orangeFury'] ?? 'border-orange-fury')
  };
}

export default { bgColorMap, borderColorMap };
