import {computed, ComputedRef, readonly, ref} from 'vue';
import {getAccessPassesForCurrentUser} from '../../../backend/access-pass/access-pass-query';
import {AccessPassStatus, AccessPassStatusType} from '../../../backend/access-pass/access-pass-types';
import {useStore} from '../../../store/store';

/**
 * Provides a singleton instance of the user's access passes, ensuring data is fetched only once per app lifecycle.
 * This composable is meant to be initialized by calling `init()` inside `onMounted()` in consuming components.
 *
 * @returns {Object}:
 * - `init()`: Fetches the user's access passes if not already initialized.
 * - `userAccessPasses`: Read-only list of the user's access passes.
 * - `userHasActiveSubscription`: `true` if the user has an active SBL Academy subscription.
 * - `userHasInactiveSubscription`: `true` if the user previously had an SBL Academy subscription but it's now inactive.
 * - `userHasContentAccess`: `true` if the user has access to content (determined by `preview.status` flags).
 * - `userHasActiveAccessPassSlug(slug: string)`: Checks if the user has an active pass that starts with the given slug.
 * - `userHasAccessPassSlug(slug: string)`: Checks if the user has any active/inactive pass that starts with the given slug.
 */

const isAccessPassActive = (status: AccessPassStatusType): boolean => {
  return status === 'active' || status === 'trial';
};

export type UserAccessComposition = {
  init: () => Promise<void>;
  userAccessPasses: ComputedRef<ReadonlyArray<AccessPassStatus>>;
  userHasActiveSubscription: ComputedRef<boolean>;
  userHasInactiveSubscription: ComputedRef<boolean>;
  userHasContentAccess: ComputedRef<boolean>;
  userHasActiveAccessPassSlug: (slug: string) => boolean;
  userHasAccessPassSlug: (slug: string) => boolean;
};

const userAccessPasses = ref<ReadonlyArray<AccessPassStatus>>([]);
const initialized = ref(false);

export function useUserAccess(): Readonly<UserAccessComposition> {
  const store = useStore();
  
  const init = async () => {
    if (initialized.value) return;
    initialized.value = true;
    try {
      const passes = await getAccessPassesForCurrentUser();
      userAccessPasses.value = passes;
    } catch (error) {
      console.error('Error fetching user access passes:', error);
    }
  };

  const userHasActiveSubscription = computed(() => {
    return userAccessPasses.value.some(
      pass => pass.slug.startsWith('ap-academy') && isAccessPassActive(pass.status)
    );
  });

  const userHasInactiveSubscription = computed(() => {
    return userAccessPasses.value.some(
      pass => pass.slug.startsWith('ap-academy') && !isAccessPassActive(pass.status)
    );
  });

  const userHasContentAccess = computed(() => {
    const hasAccess = Object.values(store.state.preview.status).some(value => value === false);
    return hasAccess;
  });
  
  const userHasActiveAccessPassSlug = (slug: string) => {
    return userAccessPasses.value.some(
      pass => pass.slug.startsWith(slug) && isAccessPassActive(pass.status)
    );
  };

  const userHasAccessPassSlug = (slug: string) => {
    return userAccessPasses.value.some(
      pass => pass.slug.startsWith(slug)
    );
  };

  return {
    init,
    userAccessPasses: computed(() => readonly(userAccessPasses.value)),
    userHasActiveSubscription,
    userHasInactiveSubscription,
    userHasContentAccess,
    userHasActiveAccessPassSlug,
    userHasAccessPassSlug
  };
}
