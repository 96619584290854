<!-- Notifications.vue -->
<template>
  <div class="relative">
    <!-- Swipe left/right messages -->
    <div
      class="absolute inset-0 flex justify-between items-center px-4 transition-colors duration-300 ease-out bg-black text-white"
      :class="{
        'transition-none bg-primary': swipeDirection === 'right',
        'transition-none bg-success': swipeDirection === 'left'
      }"
      :style="{
        opacity: translateX === SWIPE_THRESHOLD ? 0 : 1
      }"
    >
      <div class="flex flex-col items-center" style="margin-left: -1px">
        <font-awesome-icon :icon="unreadIcon" class="block mb-1 text-lg" />
        Unread
      </div>
      <div class="flex flex-col items-center" style="margin-right: 8px">
        <font-awesome-icon :icon="readIcon" class="block mb-1 text-lg" />
        Read
      </div>
    </div>

    <!-- Notification Object -->
    <li
      :class="[
        'notification-item py-5 flex items-start bg-black touch-action-pan-y',
        {
          'bg-darkest': !notification.read && !isClicked,
          'bg-black': isClicked
        }
      ]"
      :style="swipeTriggeredStyle"
      @click="handleNotificationClick(notification)"
      @touchstart.passive="handleTouchStart($event)"
      @touchmove.passive="handleTouchMove($event)"
      @touchend="handleTouchEnd"
      @mouseover="isHovered = true"
      @mouseleave="
        () => {
          isHovered = false;
          showPopup = false;
        }
      "
    >
      <!-- Dot for unread notification -->
      <div v-if="!notification.read" class="relative px-2 mt-5">
        <span class="absolute top-0 w-2 h-2 bg-primary rounded-full"></span>
      </div>

      <!-- If avatar provided, display the avatar -->
      <div v-if="notification.notificationAvatar" class="relative pr-4 ml-4">
        <img
          :src="`https://scottsbasslessons.imgix.net${notification.notificationAvatar}?auto=format`"
          alt="User avatar"
          class="w-10 h-10 rounded-full self-center"
        />
      </div>
      <!-- If no avatar provided, only set margins/padding -->
      <div v-else class="relative -ml-1 pr-6"></div>

      <div class="flex-1">
        <!-- Notification Heading -->
        <div class="flex justify-between items-start">
          <p class="text-sm text-mid">{{ notification.heading }}</p>
          <div v-if="!isHovered || isNarrow" class="flex-none">
            <p class="mx-6 text-xs text-mid italic">{{ notification.timeAgo }}</p>
          </div>
          <!-- Ellipsis icon for Read/Unread Popup -->
          <div v-else class="flex-none">
            <div class="mx-6 -mt-1" @click.stop="togglePopup">
              <font-awesome-icon
                :icon="ellipsisIcon"
                class="text-mid hover:text-light cursor-pointer"
                size="2xl"
              />
            </div>
            <!-- Popup Component (Read/Unread) -->
            <NotificationPopup
              :visible="showPopup"
              @markAsRead="markAsRead"
              @markAsUnread="markAsUnread"
            />
          </div>
        </div>

        <!-- Notification Body -->
        <div class="flex justify-between items-start">
          <p
            :class="[
              'pt-1 mr-8 text-white text-sm fixed-on-top',
              {'font-semibold': !notification.read}
            ]"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              id="htmlBody"
              @click="handleHtmlLinkClick"
              v-html="truncatedBody(notification.body, truncationLimit, showFull)"
            ></span>
            <!-- eslint-enable vue/no-v-html -->
            <span
              v-if="isBodyTruncated(notification.body, truncationLimit)"
              class="text-mid cursor-pointer underline-hover"
              @click.stop="toggleShowFull"
            >
              {{ showFull ? 'Show less' : 'Read more' }}
            </span>
          </p>
        </div>
      </div>
    </li>
  </div>
</template>

<script setup lang="ts">
import {ref, PropType, computed} from 'vue';
import {Notification} from '../vue-composition/notifications/types';
import {truncatedBody, isBodyTruncated, handleHtmlLinkClick} from './utils';
import {
  setNotificationAsRead,
  setNotificationAsUnread
} from '../vue-composition/notifications/notifications';
import {useTouchEvents} from '../vue-composition/touch/swipe-x';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faEllipsis} from '@fortawesome/pro-solid-svg-icons/faEllipsis';
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import {faEnvelopeOpen} from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpen';
import NotificationPopup from './NotificationPopup.vue';

const props = defineProps({
  notification: {type: Object as PropType<Notification>, required: true},
  isScrolling: {type: Boolean, default: false},
  isNarrow: {type: Boolean, default: false}
});

const SWIPE_THRESHOLD = 20; // pixels

const showFull = ref(false);
const isClicked = ref(false);
const isHovered = ref(false);
const showPopup = ref(false);
const ellipsisIcon = computed(() => faEllipsis);
const unreadIcon = computed(() => faEnvelope);
const readIcon = computed(() => faEnvelopeOpen);

const truncationLimit = computed(() => {
  return props.notification.notificationAvatar ? 65 : 85;
});

function toggleShowFull() {
  showFull.value = !showFull.value;
}

function handleNotificationClick(notification: Notification) {
  isClicked.value = true;
  setNotificationAsRead(notification.id);
  if (notification.notificationUrl) {
    window.open(
      notification.notificationUrl,
      notification.notificationUrlNewTab ? '_blank' : '_self'
    );
  }
}

// Touch Swipe Logic
const handleSwipe = (translateX: number) => {
  if (translateX !== 0) {
    if (translateX < 0) {
      setNotificationAsRead(props.notification.id);
      isClicked.value = true; // Reflect the "read" state
    } else if (translateX > 0) {
      setNotificationAsUnread(props.notification.id);
      isClicked.value = false; // Reflect the "unread" state
    }
  }
};

const {translateX, handleTouchStart, handleTouchMove, handleTouchEnd} = useTouchEvents(
  swipeX => {
    handleSwipe(swipeX);
    translateX.value = 0; // Always reset position after swipe
  },
  70, // Swipe threshold distance in pixels before triggering
  75, // Max swipe distance in pixels
  computed(() => props.isScrolling)
);

const swipeDirection = ref<'left' | 'right' | null>(null);
// Restrict a swipe sequence to only one direction (left or right)
const updateSwipeDirection = () => {
  if (!swipeDirection.value) {
    if (translateX.value > 0) swipeDirection.value = 'right';
    if (translateX.value < 0) swipeDirection.value = 'left';
  }
  if (translateX.value === 0) swipeDirection.value = null;
};

const constrainedTranslateX = computed(() => {
  updateSwipeDirection();
  return (swipeDirection.value === 'right' && translateX.value < 0) ||
    (swipeDirection.value === 'left' && translateX.value > 0)
    ? 0
    : translateX.value;
});

const swipeTriggeredStyle = computed(() => {
  return Math.abs(constrainedTranslateX.value) > SWIPE_THRESHOLD
    ? {
        transform: `translateX(${translateX.value}px)`,
        transition: 'transform 0.2s ease'
      }
    : {
        transition: 'transform 0.2s ease-out'
      };
});

// Popup Visibility
function togglePopup() {
  showPopup.value = !showPopup.value;
}

function markAsRead() {
  setNotificationAsRead(props.notification.id);
  showPopup.value = false;
  isClicked.value = true;
}

function markAsUnread() {
  setNotificationAsUnread(props.notification.id);
  showPopup.value = false;
  isClicked.value = false;
}
</script>

<style scoped>
.notification-item {
  position: relative;
  padding-left: 0.7rem;
  margin-bottom: 0rem;
  transition: filter 0.15s ease; /* Smooth Hover */
}
.notification-item::before {
  position: absolute;
  left: 0;
  color: white;
  font-size: 1.2rem;
  line-height: 1;
}
.notification-item:hover {
  filter: brightness(1.3); /* Brighter on Hover */
}
.underline-hover:hover {
  text-decoration: underline;
}
.touch-action-pan-y {
  touch-action: pan-y; /* Block horizontal scroll */
}
</style>

<style module>
#htmlBody > p {
  font-size: 0.875rem; /* Match text-sm */
  display: inline;
}
</style>
